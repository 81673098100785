import React from 'react';
import { Button, Typography } from '@material-ui/core';

const NoDashboards = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgb(245, 245, 245)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: 500,
        margin: 'auto',
      }}
    >
      <img width='100%' height='auto' src='/assets/art-Portfolio.svg' />
      <br />
      <Typography variant='h4'>No Dashboards Available</Typography>
      <br />
      <Button
        href='mailto:salesteam@cherre.com'
        component='a'
        size='large'
        variant='contained'
        color='primary'
      >
        Contact Sales
      </Button>
    </div>
  );
};

export default NoDashboards;
