import * as types from './types';
import constants from '../../../../const';
import { get } from 'lodash';
import { Dashboard } from 'src/products/data-connections/types';
import { FetchStatus } from 'src/products/core-prospect/redux/query/reducers';
import { Reducer } from 'redux';

export interface CoreBIDashboard extends Dashboard {
  id: string;
  title: string;
}

export type DashboardsStateType = {
  dashboards: CoreBIDashboard[];
  groupIds: number[];
  embed_url: string;
  log_user_in_url: string;
  fetchStatusDashboards: Exclude<FetchStatus['value'], undefined>;
  fetchStatusRenameDashboard: {
    value?: FetchStatus['value'];
    error?: {
      errorId: number;
      message: string;
    };
  };
  fetchStatusSoftDeleteDashboard: {
    value?: FetchStatus['value'];
    error?: {
      errorId: number;
      message: string;
    };
  };
  fetchStatusEmbedUrl?: FetchStatus['value'];
  error?: {
    errorId: number;
    message: string;
  };
};

const initialState: DashboardsStateType = {
  dashboards: [],
  groupIds: [],
  embed_url: '',
  log_user_in_url: '',
  fetchStatusDashboards: constants.LOADING,
  fetchStatusRenameDashboard: {
    value: undefined,
    error: undefined,
  },
  fetchStatusSoftDeleteDashboard: {
    value: undefined,
    error: undefined,
  },
};

type DashboardsPreviewReducer = Reducer<DashboardsStateType>;

const entities: DashboardsPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SOFT_DELETE_DASHBOARD_REQUEST: {
      return {
        ...state,
        fetchStatusSoftDeleteDashboard: {
          value: constants.LOADING,
        },
      };
    }
    case types.SOFT_DELETE_DASHBOARD_SUCCESS: {
      const deletedDashboard = action.data;

      return {
        ...state,
        fetchStatusSoftDeleteDashboard: {
          value: constants.LOADED,
        },
        dashboards: state.dashboards.filter((dashboard) => {
          return dashboard.slug !== deletedDashboard.slug;
        }),
      };
    }

    case types.SOFT_DELETE_DASHBOARD_FAILED: {
      const { error } = action;
      return {
        ...state,
        fetchStatusSoftDeleteDashboard: {
          value: constants.FAILED,
          error,
        },
      };
    }

    case types.RENAME_DASHBOARD_REQUEST: {
      return {
        ...state,
        fetchStatusRenameDashboard: {
          value: constants.LOADING,
        },
      };
    }
    case types.RENAME_DASHBOARD_SUCCESS: {
      const renamedDashboard = action.data;

      return {
        ...state,
        fetchStatusRenameDashboard: {
          value: constants.LOADED,
        },
        dashboards: state.dashboards.map((dashboard) => {
          if (dashboard.slug === renamedDashboard.slug) {
            return {
              ...dashboard,
              name: renamedDashboard.title,
            };
          }

          return dashboard;
        }),
      };
    }

    case types.RENAME_DASHBOARD_FAILED: {
      const { error } = action;
      return {
        ...state,
        fetchStatusRenameDashboard: {
          value: constants.FAILED,
          error,
        },
      };
    }

    case types.GET_DASHBOARDS_REQUEST: {
      return {
        ...state,
        fetchStatusDashboards: constants.LOADING,
      };
    }

    case types.GET_DASHBOARDS_DATA_SUCCESS: {
      const dashboards = get(action, 'data', []);
      const embed_url = get(action, 'data.embed_url', '');

      return {
        ...state,
        fetchStatusDashboards: constants.LOADED,
        dashboards,
        log_user_in_url: embed_url,
      };
    }

    case types.GET_DASHBOARDS_DATA_FAILED: {
      const { error } = action;
      return {
        ...state,
        fetchStatusDashboards: constants.FAILED,
        error,
      };
    }
    case types.GET_DASHBOARDS_EMBED_REQUEST: {
      return {
        ...state,
        embed_url: '',
        fetchStatusEmbedUrl: constants.LOADING,
      };
    }
    case types.GET_DASHBOARDS_EMBED_SUCCESS: {
      const url = get(action, 'data.url', '');

      return {
        ...state,
        embed_url: url,
        fetchStatusEmbedUrl: constants.LOADED,
      };
    }
    case types.GET_DASHBOARDS_EMBED_FAILED: {
      const { error } = action;

      return {
        ...state,
        error,
        fetchStatusEmbedUrl: constants.FAILED,
      };
    }

    default:
      return state;
  }
};

export default entities;
