import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import useIsCompact from 'src/hooks/useIsCompact';
import { useDispatch, useSelector } from 'react-redux';
import { renameDashboard } from '../redux/dashboards-preveiw/actions';
import constants from 'src/const';
import { Dashboard } from 'src/products/data-connections/types';

const useStyles = makeStyles({
  paper: {
    width: 600,
  },
});

type Render = {
  onDashboardRenamed: () => void;
  onClose: () => void;
  dashboard: Dashboard;
};

export const RenameDashboard: React.FC<Render> = ({
  onDashboardRenamed,
  dashboard,
  onClose,
}) => {
  const classes = useStyles();
  const isCompact = useIsCompact();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.profile.value);
  const isLoading = useSelector(
    (state) =>
      state.coreBI.dashboards_preview.fetchStatusRenameDashboard.value ===
      constants.LOADING
  );
  const error = useSelector(
    (state) => state.coreBI.dashboards_preview.fetchStatusRenameDashboard.error
  );

  const [title, setTitle] = useState(dashboard.name);

  return (
    <>
      <Dialog
        fullWidth={isCompact}
        classes={{ paper: classes.paper }}
        open
        onClose={onClose}
      >
        <DialogTitle>Rename Dashboard</DialogTitle>
        <DialogContent>
          <TextField
            label='Name'
            autoFocus
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            size='small'
            variant='outlined'
            fullWidth
            placeholder='Dashboard name'
            error={!!error}
            helperText={error?.message || <span>&nbsp;</span>}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClickCapture={(e) => {
              e.stopPropagation();
              onClose();
            }}
            color='primary'
          >
            close
          </Button>
          <Button
            disabled={!title || isLoading}
            onClickCapture={async (e) => {
              e.stopPropagation();
              await dispatch(
                renameDashboard({
                  dashboardSlug: dashboard.slug,
                  dashboardTitle: title,
                  organization_id: user.organizationId || 0,
                })
              );
              onDashboardRenamed();
            }}
            color='primary'
            variant='contained'
          >
            {!isLoading ? 'Rename' : 'Renaming...'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
