import React, { useEffect, useState } from 'react';
import { RootState, connect } from 'react-redux';
import {
  getDashboards,
  getDashboardEmbedUrl,
} from 'src/products/core-bi/redux/dashboards-preveiw/actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import constants from 'src/const';
import CoreBIDashboardsDrawer from './CoreBIDashboardsDrawer';
import { debounce } from '@material-ui/core/utils';
import { TabValues } from './CoreBIDashboardsDrawer/Tabs';
import { escapeRegExp } from 'lodash';
import { Dashboard as DashboardType } from '../data-connections/types';
import Dashboard from './dashboard-iframe';
import { useTheme } from '@material-ui/core';

const { NAV_BAR_HEIGHT } = constants;

type CoreBIParams = {
  dashboard_slug: string;
};

type CoreBIProps = {
  dashboards?: DashboardType[];
  getDashboards: typeof getDashboards;
  organizationId: number;
  isLookerCreatingEnabled?: boolean;
  fetchStatusDashboards: RootState['coreBI']['dashboards_preview']['fetchStatusDashboards'];
} & RouteComponentProps<CoreBIParams>;

const CoreBI = ({
  dashboards = [],
  getDashboards,
  match: { params },
  organizationId,
  isLookerCreatingEnabled = false,
  fetchStatusDashboards,
}: CoreBIProps) => {
  const theme = useTheme();

  const [tab, setTab] = React.useState(TabValues.ALL);
  const [searchText, setSearchText] = React.useState('');

  const getFilteredDashboards = () => {
    const pattern = new RegExp(escapeRegExp(searchText), 'gi');

    let tabDashboards = dashboards.filter((dashboard) => {
      if (!dashboard.slug) {
        return false;
      }

      if (tab === TabValues.CHERRE) {
        return !dashboard.isPersonal;
      }

      if (tab === TabValues.CUSTOM) {
        return dashboard.isPersonal;
      }

      return true;
    });

    if (searchText) {
      tabDashboards = tabDashboards.filter((dashboard) => {
        return (dashboard.name || '').match(pattern);
      });
    }

    return tabDashboards;
  };

  const filteredDashboards = getFilteredDashboards();

  const [isCompact, setIsCompact] = React.useState(
    theme.breakpoints.values.md > window.innerWidth
  );

  const getCurrentDashboard = () => {
    let dashboard = filteredDashboards.find(
      (dashboard) => dashboard.slug === params.dashboard_slug
    );

    if (!dashboard) {
      dashboard = filteredDashboards[0];
    }

    return dashboard;
  };

  const [currentDashboard, setCurrentDashboard] = useState(
    getCurrentDashboard()
  );

  useEffect(() => {
    getDashboards(organizationId);
  }, [organizationId]);

  useEffect(() => {
    setCurrentDashboard(getCurrentDashboard());
  }, [dashboards, organizationId, params.dashboard_slug, isCompact]);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setIsCompact(theme.breakpoints.values.md > window.innerWidth);
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    document.title = currentDashboard?.name || 'Cherre';
    return () => {
      document.title = 'Cherre';
    };
  }, [currentDashboard]);

  const isDashboardsLoaded = fetchStatusDashboards === 'LOADED';

  const isDrawerHidden = isCompact && Boolean(params.dashboard_slug);

  const isDashboardsHidden =
    !isDashboardsLoaded || (isCompact && !params.dashboard_slug);

  return (
    <div
      style={{
        height: `calc(100vh - ${NAV_BAR_HEIGHT}px`,
        display: 'flex',
      }}
    >
      {!isDrawerHidden && (
        <CoreBIDashboardsDrawer
          searchText={searchText}
          onSearchTextChange={setSearchText}
          tab={tab}
          onTabChange={setTab}
          isLookerCreatingEnabled={isLookerCreatingEnabled}
          dashboards={filteredDashboards}
          currentDashboardSlug={currentDashboard?.slug}
          refreshDashboards={() => getDashboards(organizationId)}
        />
      )}
      {!isDashboardsHidden && (
        <div
          style={{
            height: '100%',
            width: '100% ',
            display: 'flex',
            backgroundColor: 'rgb(245, 245, 245)',
          }}
        >
          {currentDashboard && (
            <Dashboard
              searchText={searchText}
              dashboards={dashboards}
              tab={tab}
              key={currentDashboard.name}
              dashboardSlug={currentDashboard.slug}
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { dashboards, log_user_in_url, fetchStatusDashboards, error } =
    state.coreBI.dashboards_preview;

  const { organizationId } = state.user.profileForm.value;

  if (!organizationId) {
    throw new Error('Organization id is not defined');
  }

  return {
    dashboards,
    log_user_in_url,
    fetchStatusDashboards,
    error,
    organizationId,
    isLookerCreatingEnabled:
      state.user?.profile?.value?.organizationProps?.looker_creating_enabled,
  };
};

export default connect(mapStateToProps, {
  getDashboards,
  getDashboardEmbedUrl,
})(withRouter(CoreBI));
