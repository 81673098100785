import React from 'react';
import { makeStyles, Tab, Tabs } from '@material-ui/core';

export enum TabValues {
  ALL = 'All',
  CHERRE = 'Cherre',
  CUSTOM = 'Custom',
}

type Props = {
  tab: TabValues;
  onChange: (tab: TabValues) => void;
};

const useStyles = makeStyles({
  tab: { minWidth: 0 },
});

export const DashboardTabs: React.FC<Props> = ({ tab, onChange }) => {
  const classes = useStyles();

  return (
    <div style={{ padding: '0 17px' }}>
      <Tabs
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        value={tab}
        onChange={(e, newValue) => onChange(newValue)}
      >
        <Tab
          classes={{ root: classes.tab }}
          value={TabValues.ALL}
          label={TabValues.ALL}
        />
        <Tab
          classes={{ root: classes.tab }}
          value={TabValues.CHERRE}
          label={TabValues.CHERRE}
        />
        <Tab
          classes={{ root: classes.tab }}
          value={TabValues.CUSTOM}
          label={TabValues.CUSTOM}
        />
      </Tabs>
    </div>
  );
};
