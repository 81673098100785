import * as types from './types';

const getDashboards = (organization_id: number) => {
  return (dispatch) => {
    return dispatch({
      url: `/organizations/${organization_id}/core_bi_dashboards`,
      types: [
        types.GET_DASHBOARDS_REQUEST,
        types.GET_DASHBOARDS_DATA_SUCCESS,
        types.GET_DASHBOARDS_DATA_FAILED,
      ],
    });
  };
};

const softDeleteDashboard = ({
  dashboardSlug,
  organization_id,
}: {
  dashboardSlug: string;
  organization_id: number;
}) => {
  return (dispatch) => {
    return dispatch({
      url: `/organizations/${organization_id}/soft-delete`,
      method: 'post',
      send: { dashboardSlug },
      types: [
        types.SOFT_DELETE_DASHBOARD_REQUEST,
        types.SOFT_DELETE_DASHBOARD_SUCCESS,
        types.SOFT_DELETE_DASHBOARD_FAILED,
      ],
    });
  };
};

const renameDashboard = ({
  dashboardSlug,
  dashboardTitle,
  organization_id,
}: {
  dashboardSlug: string;
  dashboardTitle: string;
  organization_id: number;
}) => {
  return (dispatch) => {
    return dispatch({
      url: `/organizations/${organization_id}/rename-dashboard`,
      method: 'post',
      send: { dashboardSlug, dashboardTitle },
      types: [
        types.RENAME_DASHBOARD_REQUEST,
        types.RENAME_DASHBOARD_SUCCESS,
        types.RENAME_DASHBOARD_FAILED,
      ],
    });
  };
};

const getDashboardEmbedUrl = (dashboardSlug: string) => {
  return (dispatch) => {
    return dispatch({
      url: `/dashboard_embed/${dashboardSlug}${window.location.search}`,
      types: [
        types.GET_DASHBOARDS_EMBED_REQUEST,
        types.GET_DASHBOARDS_EMBED_SUCCESS,
        types.GET_DASHBOARDS_EMBED_FAILED,
      ],
    });
  };
};

export {
  softDeleteDashboard,
  getDashboards,
  getDashboardEmbedUrl,
  renameDashboard,
};
