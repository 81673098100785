export const namespace = 'DASHBOARD_PREVIEW';
const ns = <T extends string>(name: T) => `${namespace}/${name}` as const;

export const GET_DASHBOARDS_REQUEST = ns('GET_DASHBOARDS_REQUEST');
export const GET_DASHBOARDS_DATA_SUCCESS = ns('GET_DASHBOARDS_DATA_SUCCESS');
export const GET_DASHBOARDS_DATA_FAILED = ns('GET_DASHBOARDS_DATA_FAILED');

export const RENAME_DASHBOARD_REQUEST = ns('RENAME_DASHBOARD_REQUEST');
export const RENAME_DASHBOARD_SUCCESS = ns('RENAME_DASHBOARD_SUCCESS');
export const RENAME_DASHBOARD_FAILED = ns('RENAME_DASHBOARD_FAILED');

export const SOFT_DELETE_DASHBOARD_REQUEST = ns(
  'SOFT_DELETE_DASHBOARD_REQUEST'
);
export const SOFT_DELETE_DASHBOARD_SUCCESS = ns(
  'SOFT_DELETE_DASHBOARD_SUCCESS'
);
export const SOFT_DELETE_DASHBOARD_FAILED = ns('SOFT_DELETE_DASHBOARD_FAILED');

export const GET_DASHBOARDS_EMBED_REQUEST = ns('GET_DASHBOARDS_EMBED_REQUEST');
export const GET_DASHBOARDS_EMBED_SUCCESS = ns('GET_DASHBOARDS_EMBED_SUCCESS');
export const GET_DASHBOARDS_EMBED_FAILED = ns('GET_DASHBOARDS_EMBED_FAILED');
