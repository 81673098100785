import React, { useEffect, useRef, useState } from 'react';
import { TabValues } from '../CoreBIDashboardsDrawer/Tabs';
import { connect, RootState } from 'react-redux';
import { getDashboardEmbedUrl } from '../redux/dashboards-preveiw/actions';
import constants from '../../../const';
import DashboardLoadingPage from './DashboardLoadingPage';
import CenteredMessageToUser from 'src/components/CenteredMessageToUser';
import * as T from 'src/products/data-connections/types';
import NoDashboards from './NoDashboards';
import {
  DashboardEventDetail,
  LookerEmbedDashboard,
  LookerEmbedSDK,
} from '@looker/embed-sdk';
import { makeStyles } from '@material-ui/core';
import DashboardControlPanel from './DashboardControlPanel';
import { useHistory } from 'react-router';

type EmbeddedDashboardProps = {
  dashboardSlug: string;
  dashboards: T.Dashboard[];
  searchText: string;
  tab: TabValues;
  embed_url: string;
  fetchStatusEmbedUrl: RootState['coreBI']['dashboards_preview']['fetchStatusEmbedUrl'];
  error: RootState['coreBI']['dashboards_preview']['error'];
  getDashboardEmbedUrl: typeof getDashboardEmbedUrl;
};

const useClasses = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  dashboardContainer: {
    height: '100%',
    width: '100%',
    '& > iframe': {
      height: '100%',
      width: '100%',
    },
  },
}));

const EmbeddedDashboard = ({
  dashboardSlug,
  dashboards,
  searchText,
  tab,
  embed_url,
  fetchStatusEmbedUrl,
  error,
  getDashboardEmbedUrl,
}: EmbeddedDashboardProps) => {
  const hasDashboards = dashboards.length > 0;

  const hasCherreDashboards =
    dashboards.filter((dashboard) => !dashboard.isPersonal).length > 0;

  const dashboardContainerRef = useRef<HTMLDivElement>(null);
  const [dashboard, setDashboard] = useState<
    LookerEmbedDashboard | undefined
  >();
  const [dashboardInfo, setDashboardInfo] = useState<
    DashboardEventDetail | undefined
  >();

  const { push } = useHistory();
  const classes = useClasses();

  useEffect(() => {
    const ref = dashboardContainerRef.current;
    if (embed_url && ref) {
      LookerEmbedSDK.init(new URL(embed_url).origin);
      LookerEmbedSDK.createDashboardWithUrl(embed_url)
        .appendTo(ref)
        .on(
          'dashboard:loaded',
          (e: DashboardEventDetail) =>
            e.dashboard && setDashboardInfo(e.dashboard)
        )
        .withClassName('looker-embed')
        .build()
        .connect()
        .then((dash) => setDashboard(dash));
    }
  }, [embed_url]);

  useEffect(() => {
    const listener = function (event) {
      if (
        event.source ===
          (
            document.getElementsByClassName(
              'looker-embed'
            )?.[0] as HTMLIFrameElement
          )?.contentWindow &&
        event.type === 'message'
      ) {
        const dashboard = dashboards.find((d) => d.slug === event.data);

        if (dashboard) {
          push(`/dashboards/${dashboard.slug}`);
        }
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  useEffect(() => {
    if (dashboardSlug !== '') {
      getDashboardEmbedUrl(dashboardSlug);
    }
  }, [dashboardSlug]);

  if (tab === TabValues.ALL && !hasDashboards && !searchText) {
    return <NoDashboards />;
  }

  if (tab === TabValues.CHERRE && !hasCherreDashboards && !searchText) {
    return <NoDashboards />;
  }

  if (fetchStatusEmbedUrl === constants.FAILED) {
    return (
      <CenteredMessageToUser
        height={200}
        topMessage={error?.message}
        messageDetail={`Please contact support with errorId: ${
          error?.errorId || 0
        }`}
      />
    );
  }

  return (
    <div className={classes.container}>
      <DashboardControlPanel
        dashboard={dashboard}
        dashboardInfo={dashboardInfo}
      />
      {fetchStatusEmbedUrl === constants.LOADED ? (
        <div
          ref={dashboardContainerRef}
          className={classes.dashboardContainer}
          title='Dashboard'
        />
      ) : (
        <DashboardLoadingPage />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { embed_url, fetchStatusEmbedUrl, error } =
    state.coreBI.dashboards_preview;

  return {
    embed_url: embed_url,
    fetchStatusEmbedUrl,
    error,
  };
};

export default connect(mapStateToProps, { getDashboardEmbedUrl })(
  EmbeddedDashboard
);
