import React from 'react';
import { DashboardEventDetail, LookerEmbedDashboard } from '@looker/embed-sdk';
import { useSelector } from 'react-redux';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export type DashboardControlPanelProps = {
  dashboard: LookerEmbedDashboard | undefined;
  dashboardInfo: DashboardEventDetail | undefined;
};

const dashboards = {
  colliers: React.lazy(() => import('./ColliersControlPanel')),
};

const DashboardControlPanel: React.FC<DashboardControlPanelProps> = (props) => {
  const theme = useSelector((state) => state.user.profile.value.themeName);
  const isDashboardControlPanelEnabled = useFeatureFlag(
    'CoreBIDashboardControlPanel'
  );
  if (!isDashboardControlPanelEnabled) {
    return <span />;
  }
  const Component = dashboards[theme || ''];
  return Component ? <Component {...props} /> : <span />;
};

export default DashboardControlPanel;
