import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { CreateDashboard } from './CreateDashboard';
import { grey } from '@material-ui/core/colors';
import { Dashboard } from 'src/products/data-connections/types';
import { useSelector } from 'react-redux';
import { isCreatingEnabledSelector } from 'src/products/shell/pages/Home/HomePageComponents/DashboardsSelector';

const useStyles = makeStyles({
  icon: {
    fontSize: 38,
    color: grey[500],
  },
  title: {
    margin: '10px 0',
    color: grey[500],
  },
  button: {
    margin: '20px 0',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
  },
});

type DashboardsAvailabilityProps = {
  dashboards: Dashboard[];
  onDashboardCreated: (slug: string) => void;
};

const DashboardsAvailability = ({
  dashboards,
  onDashboardCreated,
}: DashboardsAvailabilityProps) => {
  const classes = useStyles();

  const isLookerCreatingEnabled = useSelector(isCreatingEnabledSelector);

  if (dashboards.length > 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Search className={classes.icon} />
      <Typography className={classes.title} align='center' variant='body1'>
        Looks like you don't have <br /> Custom Dashboards yet.
      </Typography>
      {isLookerCreatingEnabled && (
        <CreateDashboard onDashboardCreated={onDashboardCreated}>
          {({ open }) => (
            <Button
              className={classes.button}
              size='large'
              variant='contained'
              color='primary'
              onClick={() => open()}
            >
              Create New
            </Button>
          )}
        </CreateDashboard>
      )}
    </div>
  );
};

export default DashboardsAvailability;
