import React from 'react';

import { Redirect, useParams } from 'react-router';

export const DashboardRedirect: React.FC = () => {
  const { dashboard_slug } = useParams<{ dashboard_slug: string }>();
  if (window !== window.parent) {
    // If the app is running in an iframe
    window.parent.postMessage(dashboard_slug, '*');
    return null;
  }

  return <Redirect to={`/dashboards/${dashboard_slug}`} />;
};
