import React from 'react';
import { Typography, Link, ListItem, makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { Link as RouterLink } from 'react-router-dom';
import { DashboardListItemMenu } from './DashboardListItemMenu';
import useIsCompact from 'src/hooks/useIsCompact';
import { Dashboard } from 'src/products/data-connections/types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 10,
    border: ({ isActive }: { isActive: boolean }) =>
      isActive
        ? `2px solid ${theme.palette.primary.main}`
        : '2px solid #e0e0e0',
    background: ({ isActive }: { isActive: boolean }) =>
      isActive ? '#f5f5f5' : '#fff',
    borderRadius: 6,
    width: '100%',
  },
  ellipsis: {
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    display: 'inline-block',
    float: 'left',
    width: '100%',
  },
}));

type Props = {
  index: number;
  dashboard: Dashboard;
  currentDashboardSlug: string;
  onDashboardDelete: (dashboard: Dashboard) => void;
};

const DashboardListItem: React.FC<Props> = ({
  dashboard,
  index,
  currentDashboardSlug,
  onDashboardDelete,
}) => {
  const isCompact = useIsCompact();
  const isActive = !isCompact && dashboard.slug === currentDashboardSlug;

  const classes = useStyles({ isActive });

  const title = dashboard.name.split(' (')[0];
  const subTitle = dashboard.name.match(/\(.*\)/gi);

  return (
    <Link
      onClick={() =>
        window.analytics?.track('User Selects Dashboard on Menu', {
          dashboardSlug: dashboard.slug,
        })
      }
      component={RouterLink}
      to={`/dashboards/${dashboard.slug}`}
      underline={'none'}
      color={'inherit'}
    >
      <div style={{ position: 'relative' }}>
        {dashboard.isPersonal ? (
          <DashboardListItemMenu
            onDashboardDelete={onDashboardDelete}
            dashboard={dashboard}
          />
        ) : null}
        <ListItem
          disableRipple
          id={`js-dashboard-list-item-${index}`}
          className={`js-dashboard-list-item-${dashboard.slug}`}
          button
          key={index}
          classes={{
            root: classes.root,
          }}
        >
          <Typography
            className={classes.ellipsis}
            style={{ fontWeight: 600, fontSize: 14, fontFamily: 'Montserrat' }}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              className={classes.ellipsis}
              style={{
                fontWeight: 600,
                fontSize: 12,
                color: grey[600],
                fontFamily: 'Montserrat',
              }}
            >
              {subTitle[0]}
            </Typography>
          )}
        </ListItem>
      </div>
    </Link>
  );
};

export default DashboardListItem;
