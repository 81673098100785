import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import useIsCompact from 'src/hooks/useIsCompact';
import { CreateDashboard } from './CreateDashboard';
import { DrawerButton } from './DrawerButton';

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  root: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '32px 1fr 75px',
    columnGap: 15,
    padding: 17,
  },
});

type Props = {
  toggleDrawer: () => void;
  isDrawerOpen: boolean;
  onDashboardCreated: (dashboardSlug: string) => void;
  isCreatingEnabled: boolean;
};

export const Header: React.FC<Props> = ({
  toggleDrawer,
  isDrawerOpen,
  onDashboardCreated,
  isCreatingEnabled,
}) => {
  const classes = useStyles();
  const isCompact = useIsCompact();

  return (
    <div className={classes.root}>
      {!isCompact && (
        <DrawerButton onClick={toggleDrawer} isDrawerOpen={isDrawerOpen} />
      )}

      <Typography className={classes.title}>Dashboards</Typography>

      {isCreatingEnabled && (
        <CreateDashboard onDashboardCreated={onDashboardCreated}>
          {({ open }) => {
            return (
              <Button
                size='small'
                variant='contained'
                color='primary'
                onClick={open}
              >
                + New
              </Button>
            );
          }}
        </CreateDashboard>
      )}
    </div>
  );
};
