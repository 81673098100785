import React, { ReactElement, useState } from 'react';
import { push } from 'connected-react-router';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import useIsCompact from 'src/hooks/useIsCompact';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  paper: {
    width: 600,
  },
});

type Render = {
  onDashboardCreated?: (slug: string) => void;
  children: (params: { open: () => void; close: () => void }) => ReactElement;
};

export const CreateDashboard: React.FC<Render> = ({
  children,
  onDashboardCreated,
}) => {
  const classes = useStyles();
  const isCompact = useIsCompact();
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | void>();

  const [isOpen, toggleIsOpen] = React.useState<boolean>(false);

  const open = () => toggleIsOpen(true);
  const close = () => {
    setTitle('');
    setError();
    toggleIsOpen(false);
  };

  const createDashboard = () => {
    return axios.post<{ slug: string }>('/api/v1/create-dashboard', { title });
  };

  return (
    <>
      <Dialog
        fullWidth={isCompact}
        classes={{ paper: classes.paper }}
        open={isOpen}
        onClose={close}
      >
        <DialogTitle>Create Dashboard</DialogTitle>
        <DialogContent>
          <TextField
            label='Name'
            autoFocus
            value={title}
            onChange={(e) => {
              setError();
              setTitle(e.target.value);
            }}
            size='small'
            variant='outlined'
            fullWidth
            placeholder='Dashboard name'
            error={!!error}
            helperText={error?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color='primary'>
            close
          </Button>
          <Button
            disabled={!title || isLoading}
            onClick={async () => {
              setIsLoading(true);
              try {
                const {
                  data: { slug },
                } = await createDashboard();

                if (onDashboardCreated) {
                  await onDashboardCreated(slug);
                }

                dispatch(push(`/dashboards/${slug}`));
                close();
              } catch (e: any) {
                if (e.response?.data?.errors) {
                  setError(e.response.data.errors[0]);
                } else {
                  setError(e);
                }
              } finally {
                setIsLoading(false);
              }
            }}
            color='primary'
            variant='contained'
          >
            {!isLoading ? 'Create' : 'Creating...'}
          </Button>
        </DialogActions>
      </Dialog>
      {children({ open, close })}
    </>
  );
};
