import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import useIsCompact from 'src/hooks/useIsCompact';
import { useDispatch, useSelector } from 'react-redux';
import { softDeleteDashboard } from '../redux/dashboards-preveiw/actions';
import constants from 'src/const';
import { Dashboard } from 'src/products/data-connections/types';

const useStyles = makeStyles({
  paper: {
    width: 600,
  },
});

type Props = {
  onDashboardDeleted: () => void;
  onClose: () => void;
  dashboard: Dashboard;
};

export const DeleteDashboard: React.FC<Props> = ({
  onDashboardDeleted,
  dashboard,
  onClose,
}) => {
  const classes = useStyles();
  const isCompact = useIsCompact();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.profile.value);

  const isLoading = useSelector(
    (state) =>
      state.coreBI.dashboards_preview.fetchStatusSoftDeleteDashboard.value ===
      constants.LOADING
  );
  const error = useSelector(
    (state) =>
      state.coreBI.dashboards_preview.fetchStatusSoftDeleteDashboard.error
  );

  return (
    <>
      <Dialog
        fullWidth={isCompact}
        classes={{ paper: classes.paper }}
        open
        onClose={onClose}
      >
        <DialogTitle>Delete Dashboard</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to delete the <b>{dashboard.name}</b> dashboard. Once
            deleted it cannot be restored
          </DialogContentText>
          {error?.message ? (
            <DialogContentText>{error.message}</DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClickCapture={(e) => {
              e.stopPropagation();
              onClose();
            }}
            color='primary'
          >
            close
          </Button>
          <Button
            disabled={isLoading}
            onClickCapture={async (e) => {
              e.stopPropagation();
              await dispatch(
                softDeleteDashboard({
                  dashboardSlug: dashboard.slug,
                  organization_id: user.organizationId || 0,
                })
              );
              onDashboardDeleted();
            }}
            color='primary'
            variant='contained'
          >
            {!isLoading ? 'Delete' : 'Deleting...'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
