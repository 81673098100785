import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const DashboardLoadingPage = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'initial',
        position: 'relative',
      }}
    >
      <LinearProgress />
    </div>
  );
};

export default DashboardLoadingPage;
