import React, { useState } from 'react';
import { push } from 'connected-react-router';
import {
  Box,
  Drawer,
  List,
  makeStyles,
  TextField as MUITextField,
  Theme,
  withStyles,
} from '@material-ui/core';
import DashboardListItem from './DashboardListItem';
import Search from '@material-ui/icons/Search';
import { grey } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from './Header';
import { DrawerButton } from './DrawerButton';
import zIndex from '@material-ui/core/styles/zIndex';
import { DashboardTabs, TabValues } from './Tabs';
import DashboardsAvailability from './DashboardsAvailability';
import DeleteSnackbar from 'src/products/core-prospect/search-pages/components/Sidebar/components/DeleteSnackbar';
import { Dashboard } from 'src/products/data-connections/types';

const drawerWidth = '350px';

const useStyles = makeStyles<
  Theme,
  { open: boolean; isEmulatedSession: boolean }
>((theme) => ({
  drawerHeader: {
    padding: '20px 17px',
  },
  drawer: {
    width: ({ open }) => (open ? drawerWidth : theme.spacing(2)),
    borderRight: ({ open }) => (open ? 'none' : `2px solid ${grey[300]}`),
    transition: theme.transitions.create('width', {
      easing: 'linear',
      duration: '200ms',
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: ({ isEmulatedSession }) =>
      isEmulatedSession ? theme.spacing(12) : theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  closeDrawer: {
    position: 'absolute',
    left: 5,
    top: 13,
    zIndex: zIndex.drawer,
  },
}));

const TextField = withStyles({
  root: {
    marginBottom: 15,
  },
})(MUITextField);

type CoreBIDashboardsDrawerProps = {
  tab: TabValues;
  onTabChange: React.Dispatch<React.SetStateAction<TabValues>>;
  searchText: string;
  onSearchTextChange: React.Dispatch<React.SetStateAction<string>>;
  dashboards: Dashboard[];
  currentDashboardSlug: string;
  refreshDashboards: () => void;
  isLookerCreatingEnabled: boolean;
};

const CoreBIDashboardsDrawer = ({
  tab,
  onTabChange,
  searchText,
  onSearchTextChange,
  dashboards = [],
  currentDashboardSlug,
  refreshDashboards,
  isLookerCreatingEnabled,
}: CoreBIDashboardsDrawerProps) => {
  const [isDeleteSnackbarVisible, setDeleteIsSnackbarVisible] = useState(false);
  const initialDrawerState = useSelector(
    (state) =>
      !state.user?.profile?.value?.organizationProps?.dashboard_landing_page
  );
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(initialDrawerState);
  const dispatch = useDispatch();

  const toggleDrawer = () => setIsDrawerOpen((isOpen) => !isOpen);

  const { isEmulatedSession = false } = useSelector((state) => ({
    isEmulatedSession: state.user.profile.value.isEmulatedSession,
  }));

  const classes = useStyles({ open: isDrawerOpen, isEmulatedSession });

  const onDashboardCreated = async (dashboardSlug) => {
    await refreshDashboards();
    onTabChange(TabValues.CUSTOM);

    window.analytics?.track('User Selects "New"');

    const auctionEl = document.getElementsByClassName(
      `js-dashboard-list-item-${dashboardSlug}`
    )?.[0];

    setTimeout(() => {
      if (auctionEl && auctionEl.scrollIntoView) {
        auctionEl.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={isDrawerOpen}
        data-testid='drawer'
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Header
          isCreatingEnabled={isLookerCreatingEnabled}
          onDashboardCreated={onDashboardCreated}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
        />
        <DashboardTabs tab={tab} onChange={onTabChange} />
        <Box className={classes.drawerHeader}>
          <TextField
            fullWidth
            value={searchText}
            onChange={(e) => {
              window.analytics?.track('User Searches Dashboards', {
                text: e.target.value,
              });
              onSearchTextChange(e.target.value);
            }}
            InputProps={{
              endAdornment: <Search style={{ color: grey[500] }} />,
            }}
            variant='outlined'
            size='small'
            placeholder='Search dashboard'
          />
          {tab === TabValues.CUSTOM && (
            <DashboardsAvailability
              onDashboardCreated={onDashboardCreated}
              dashboards={dashboards.filter(
                (dashboard) => dashboard.isPersonal
              )}
            />
          )}
          <List>
            {dashboards.map((dashboard, index) => {
              return (
                <DashboardListItem
                  index={index}
                  key={dashboard.slug}
                  dashboard={dashboard}
                  currentDashboardSlug={currentDashboardSlug}
                  onDashboardDelete={(deletedDashboard) => {
                    const remainingDashboards = dashboards.filter(
                      (dashboard) => dashboard.slug !== deletedDashboard.slug
                    );
                    setDeleteIsSnackbarVisible(true);

                    const isCurrentDashboardDeleted =
                      deletedDashboard.slug === currentDashboardSlug;

                    if (isCurrentDashboardDeleted) {
                      dispatch(
                        push(
                          `/dashboards/${remainingDashboards[0]?.slug || ''}`
                        )
                      );
                    }
                  }}
                />
              );
            })}
          </List>
        </Box>
      </Drawer>
      {isDeleteSnackbarVisible ? (
        <DeleteSnackbar
          isOpen
          onClose={() => setDeleteIsSnackbarVisible(false)}
          text={`Dashboard deleted`}
        />
      ) : null}
      {!isDrawerOpen && (
        <DrawerButton
          isDrawerOpen={isDrawerOpen}
          onClick={toggleDrawer}
          className={classes.closeDrawer}
        />
      )}
    </>
  );
};

export default CoreBIDashboardsDrawer;
