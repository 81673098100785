import React from 'react';
import { grey } from '@material-ui/core/colors';
import { Avatar, makeStyles } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

const useStyles = makeStyles((theme) => ({
  badge: {
    cursor: 'pointer',
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: 'white',
    boxShadow: '0 3px 8px ' + grey[600],
    '& > svg': {
      color: 'black',
      width: '20px',
      height: '20px',
    },
  },
}));

type Props = {
  isDrawerOpen: boolean;
  onClick: () => void;
  className?: string;
};

export const DrawerButton: React.FC<Props> = ({
  isDrawerOpen,
  onClick,
  className,
}) => {
  const classes = useStyles();

  return (
    <Avatar
      className={`${classes.badge} ${className || ''}`}
      data-testid='drawer-btn'
      onClick={onClick}
    >
      {isDrawerOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </Avatar>
  );
};
