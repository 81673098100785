import React from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

import { RenameDashboard } from './RenameDashboard';
import { DeleteDashboard } from './DeleteDashboard';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { Dashboard } from 'src/products/data-connections/types';

const useStyles = makeStyles({
  listItemIconRoot: {
    minWidth: 'auto',
    marginRight: 5,
  },
  icon: {
    fontSize: 16,
  },
});

type Props = {
  dashboard: Dashboard;
  onDashboardDelete: (dashboard: Dashboard) => void;
};

const DashboardListItemMenu: React.FC<Props> = ({
  dashboard,
  onDashboardDelete,
}) => {
  const classes = useStyles();

  const options = [
    {
      text: 'Rename',
      icon: <Edit classes={{ root: classes.icon }} />,
      onClick: ({ setIsRenameDialogOpen }) => setIsRenameDialogOpen(true),
    },
    {
      text: 'Delete',
      icon: <Delete classes={{ root: classes.icon }} fontSize='small' />,
      onClick: ({ setIsDeleteDialogOpen }) => setIsDeleteDialogOpen(true),
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const [isRenameDialogOpen, setIsRenameDialogOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 100,
        top: 4,
        right: 2,
      }}
    >
      <IconButton
        size='small'
        style={{ width: 20, height: 20 }}
        onClickCapture={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleClick(e);
        }}
      >
        <MoreVert style={{ width: 15, height: 15 }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem
            key={option.text}
            onClickCapture={(e) => {
              e.stopPropagation();
              option.onClick({
                setIsRenameDialogOpen,
                setIsDeleteDialogOpen,
              });
            }}
          >
            <ListItemIcon
              classes={{
                root: classes.listItemIconRoot,
              }}
            >
              {option.icon}
            </ListItemIcon>
            <ListItemText>{option.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      {isRenameDialogOpen ? (
        <RenameDashboard
          dashboard={dashboard}
          onDashboardRenamed={() => {
            setAnchorEl(null);
            setIsRenameDialogOpen(false);
          }}
          onClose={() => {
            setIsRenameDialogOpen(false);
            setAnchorEl(null);
          }}
        />
      ) : null}

      {isDeleteDialogOpen ? (
        <DeleteDashboard
          dashboard={dashboard}
          onDashboardDeleted={() => {
            setAnchorEl(null);
            setIsDeleteDialogOpen(false);
            onDashboardDelete(dashboard);
          }}
          onClose={() => {
            setIsDeleteDialogOpen(false);
            setAnchorEl(null);
          }}
        />
      ) : null}
    </div>
  );
};

export { DashboardListItemMenu };
