import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { DashboardControlPanelProps } from '.';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Replay from '@material-ui/icons/Replay';
import { CoreBIDashboard } from '../../redux/dashboards-preveiw/reducers';

const useClasses = makeStyles((theme) => ({
  container: {
    display: 'grid',
    padding: '10px 20px',
    gridTemplateAreas: `
      "update reset home links . logo"
      "line line line line line line"
    `,
    gridTemplateColumns: 'auto auto auto auto 1fr auto',
    justifyItems: 'center',
    alignItems: 'center',
  },
  line: {
    gridArea: 'line',
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    margin: '10px',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  link: {
    color: '#c0d0e2',
    padding: '15px',
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  linkSelected: {
    color: theme.palette.primary.main,
    padding: '15px',
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  reloadIcon: {
    transform: 'scaleX(-1)',
  },
}));

const translateDashboardName = {
  JC0fSaYMhuPxmVYCiLtGhu: 'Home',
  I7OhqjyUdLfAYdOjGHLr0D: 'Buyers',
  RUItiksvY7d14cbRl914mf: 'Funds',
  ULDMnsSAxE2NuqbRAgXWaX: 'Properties',
  '2nUHx5opRGk04o8ev7jOnx': 'Market Intel',
};

const ColliersControlPanel: React.FC<DashboardControlPanelProps> = ({
  dashboard,
  dashboardInfo,
}) => {
  const history = useHistory();
  const classes = useClasses();
  const dashboards = useSelector(
    (state) => state.coreBI.dashboards_preview.dashboards
  );
  const onUpdate = () => {
    dashboard?.run();
  };

  const onReset = () => {
    if (dashboardInfo) {
      dashboard?.updateFilters(dashboardInfo.dashboard_filters);
      dashboard?.run();
    }
  };

  const currentDashboard = dashboards.find(
    (d) => Number(d.id) === Number(dashboardInfo?.id)
  );

  const isOnMainDashboard = Object.keys(translateDashboardName).includes(
    currentDashboard?.slug || ''
  );

  const isOnHome = currentDashboard?.slug
    ? currentDashboard?.slug === 'JC0fSaYMhuPxmVYCiLtGhu'
    : true;

  const links = [
    dashboards.find((d) => d.slug === 'JC0fSaYMhuPxmVYCiLtGhu'),
    isOnMainDashboard
      ? null
      : dashboards.find((d) => Number(d.id) === Number(dashboardInfo?.id)),
    dashboards.find((d) => d.slug === 'I7OhqjyUdLfAYdOjGHLr0D'),
    dashboards.find((d) => d.slug === 'RUItiksvY7d14cbRl914mf'),
    dashboards.find((d) => d.slug === 'ULDMnsSAxE2NuqbRAgXWaX'),
    dashboards.find((d) => d.slug === '2nUHx5opRGk04o8ev7jOnx'),
  ].filter((d): d is CoreBIDashboard => Boolean(d));

  return (
    <div className={classes.container}>
      {!isOnHome && (
        <Button
          className={classes.button}
          style={{ gridArea: 'update' }}
          endIcon={<Replay className={classes.reloadIcon} />}
          onClick={onUpdate}
        >
          Update
        </Button>
      )}
      {!isOnHome && (
        <Button
          className={classes.button}
          style={{ gridArea: 'reset' }}
          endIcon={<Replay />}
          onClick={onReset}
        >
          Reset
        </Button>
      )}
      <div style={{ gridArea: 'links' }}>
        {links.map((l) => (
          <a
            className={
              l.id === currentDashboard?.id
                ? classes.linkSelected
                : classes.link
            }
            onClick={() => history.push(`/dashboards/${l.slug}`)}
          >
            {translateDashboardName[l.slug] || l.name}
          </a>
        ))}
      </div>
      <img
        style={{ gridArea: 'logo', height: '40px' }}
        src='https://www.dropbox.com/s/u4ps7q4l2e4jtyt/Colliers%20-%20Image%20-%20transparent%20background.png?raw=1'
      />
      <div className={classes.line} />
    </div>
  );
};

export default ColliersControlPanel;
